/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-16 21:51:49
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-16 21:52:48
 */
import ActivityTips from './ActivityTips.vue'
export default ActivityTips
