/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-26 21:13:22
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-27 16:14:17
 */
import Vue from 'vue'

class CheckStatus {
  constructor (status, date) {
    this.status = status
    this.date = date
  }

  check () {
    if (this.status === 0) {
      Vue.wToast.warning('请先完成开户认证')
      return false
    } else if (this.status === 1 || this.status === 2) {
      Vue.wToast.warning('请等待账户认证通过')
      return false
    } else if (Date.parse(new Date()) > Date.parse(new Date(this.date.replace(/-/g, '/')))) {
      Vue.wToast.warning('账户套餐已到期，请到账户管理处续费后使用')
      return false
    } else {
      return true
    }
  }
}

export default CheckStatus
