/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-16 21:51:40
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-26 17:09:47
 */
export default {
  name: 'AppActivityTips',
  data () {
    return {
      activityTipsShow: false,
      isFirst: false
    }
  },
  methods: {
    async updatedTipsShow () {
      this.activityTipsShow = !this.activityTipsShow
      this.$emit('change', this.activityTipsShow)
      if (this.isFirst) {
        await this.$wPost('/common/user/operate/flag/add.do', { operate_type: 2 })
      }
    }
  },
  async created () {
    const data = await this.$wPost('/common/user/operate/flag/checkExist.do', { operate_type: 2 })
    if (data === true) {
      this.activityTipsShow = data
      this.$emit('change', data)
      this.isFirst = data
    }
  }
}
