/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-17 09:13:58
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-06 16:59:38
 */
import HandelData from '@/service/Activity'
import EnumMap from '@/plugins/enumMap'
import { getToday, copyText, numberFormat } from '@/plugins/utils'
import CheckStatus from '@/plugins/checkStatus'
import { mapGetters } from 'vuex'

export default {
  name: 'AppActivityTable',
  data () {
    return {
      date: [],
      queryForm: {
        media_platform: 0,
        data_type: 0,
        keyword: '',
        start_date: '',
        end_date: '',
        field_type: '',
        sort_type: ''
      }
    }
  },
  watch: {
    date (val, old) {
      if (val) {
        this.$set(this.queryForm, 'start_date', val[0])
        this.$set(this.queryForm, 'end_date', val[1])
      }
    }
  },
  computed: {
    ...mapGetters([
      'enum_map',
      'userInfo'
    ])
  },
  methods: {
    handleCommand (e) {
      console.log(e)
      switch (e.type) {
        case 'status':
          this.status(e)
          break
        case 'edit':
          this.edit(e.id)
          break;
        case 'test':
          this.test(e)
          break;
        case 'delete':
          this.delete(e.id)
          break;
        default:
          break;
      }
    },
    crowd (row) {
      if (new CheckStatus(this.userInfo.status, this.userInfo.avaliable_date).check()) {
        this.$store.dispatch('App/Datapackage/Crowdattribute/updatePageTitle', `活动单元名称：${row.name}`, { root: true })
        this.$router.push({ path: '/app/activity/crowdattribute', query: { activity_id: row.id } })
      }
    },
    numFormat (val) {
      return numberFormat(val)
    },
    activityStatus (val) {
      const name = new EnumMap('activity_status').mapping(val)
      return name
    },
    tableSearch (reset, sort) {
      if (!sort) {
        this.queryForm.field_type = ''
        this.queryForm.sort_type = ''
      }
      if (reset) {
        this.$refs['w-table'].resetList()
      } else {
        this.$refs['w-table'].getList()
      }
    },
    tableSort (val) {
      this.queryForm.field_type = val.fieldType
      this.queryForm.sort_type = val.sort_type
      this.tableSearch(true, true)
    },
    async status (e) {
      this.$refs['w-table'].updateLoading(true)

      const payload = new HandelData(e).updateStatus()

      const data = await this.$wPost('/app/activity/update_status.do', payload)

      if (data === true) {
        this.$wToast.success(`${payload.status === 1 ? '启用' : '停用'}成功`)
        this.tableSearch(true)
      } else {
        this.$refs['w-table'].updateLoading(false)
      }
    },
    edit (id) {
      this.$router.push({ path: `/app/activity/edit/${id}` })
    },
    test (e) {
      if (new CheckStatus(this.userInfo.status, this.userInfo.avaliable_date).check()) {
        this.$router.push({ path: `/app/activity/test/${e.id}`, query: { url: e.url } })
      }
    },
    delete (id) {
      const self = this
      self.$confirm('您确认删除此条活动单元吗？<br /> 删除后此活动单元收集的数据将无法用于人群包创建', '', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'w-table-delete'
      }).then(async () => {
        self.$refs['w-table'].updateLoading(true)
        const payload = Object.assign({}, {
          id
        })
        const data = await self.$wPost('/app/activity/delete.do', payload)
        if (data === true) {
          self.$wToast.success('删除成功')
          self.tableSearch(true)
        } else {
          self.$refs['w-table'].updateLoading(false)
        }
      }).catch(() => {

      });
    },
    copyUrl (url) {
      return copyText(url)
    }
  },
  created () {
    this.date = getToday()
  }
}
