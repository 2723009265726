/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-16 21:24:31
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-26 21:41:44
 */
import ActivityTips from './ActivityTips'
import ActivityTable from './ActivityTable'
import { mapGetters } from 'vuex'
import CheckStatus from '@/plugins/checkStatus'
export default {
  name: 'AppActive',
  components: {
    ActivityTips,
    ActivityTable
  },
  data () {
    return {
      activityTipsShow: false
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  methods: {
    toggleTips () {
      this.$refs['activity-tips'].updatedTipsShow()
    },
    changeActivityTipsShow (status) {
      this.activityTipsShow = status
    },
    add () {
      if (new CheckStatus(this.userInfo.status, this.userInfo.avaliable_date).check()) {
        this.$router.push({ path: '/app/activity/add' })
      }
    }
  }
}
