var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('w-box-header',[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("活动管理")]),_c('span',{attrs:{"slot":"button"},slot:"button"},[_c('w-button',{ref:"testButton",staticClass:"w-header-btn",attrs:{"round":""},on:{"click":_vm.toggleTips}},[_c('span',{style:({
        display: 'flex',
        alignItems: 'center'
      })},[_c('i',{staticClass:"ab_font_arrow_black_down",style:({
          transition: 'all .2s ease',
          transform: _vm.activityTipsShow ? 'rotate(0deg)' : 'rotate(-180deg)',
          fontSize: '10px'
        })}),_c('span',{style:({ marginLeft: '5px' })},[_vm._v(_vm._s(_vm.activityTipsShow ? "收起" : "展开")+"使用说明")])])]),_c('w-button',{ref:"testButton",staticClass:"w-header-btn",attrs:{"type":"primary","round":""},on:{"click":_vm.add}},[_vm._v("新建活动单元")])],1)]),_c('activity-tips',{ref:"activity-tips",on:{"change":_vm.changeActivityTipsShow}}),_c('activity-table')],1)}
var staticRenderFns = []

export { render, staticRenderFns }